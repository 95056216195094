import React, { Component, createRef, useState } from "react";
import Navbar from "../common/navBar1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import Swal from "sweetalert2";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Footer from "../common/footer";
import DoctorCard from "../common/doctorCard";
import { withRouter } from "../utils/withRouter";
// import { Navigation } from 'swiper';
import withRouoter from "./index";
class Index extends Component {
  state = {
    windowSize: window.innerWidth,
    home: "",
    bookAppointment: "",
    doctors: [],
    services: [],
    doctorsList: [],
  };

  handleBook = (id) => {
    this.props.navigate(`/appointment/${id}`);
  };

  async componentDidMount() {
    const response = await axios.get(
      "https://appointment.whethiopia.com/filter/getAppointmentDepartmentList.php"
    );
    const services = response.data;
    this.setState({ services });
    const resp = await axios.get(
      "https://appointment.whethiopia.com/filter/getAppointmentOpdUnitList.php"
    );
    const doctors = resp.data;

    this.setState({ doctors });
    this.setState({ doctorsList: doctors });
    //console.log(resp.data);
  }
  // handleViewProfile = (id) => {
  //   let timerInterval;
  //   this.props.navigate(`/profile/${id}`);
  //   //   Swal.fire({
  //   //     title: "Loading",
  //   //     // html: "I will close in <b></b> milliseconds.",
  //   //     timer: 200000,
  //   //     timerProgressBar: true,
  //   //     didOpen: () => {
  //   //       Swal.showLoading();
  //   //       const b = Swal.getHtmlContainer().querySelector("b");
  //   //       timerInterval = setInterval(() => {
  //   //         b.textContent = Swal.getTimerLeft();
  //   //       }, 100);
  //   //     },
  //   //     willClose: () => {
  //   //       clearInterval(timerInterval);
  //   //     },
  //   //   }).then((result) => {
  //   //     /* Read more about handling dismissals below */
  //   //     if (result.dismiss === Swal.DismissReason.timer) {
  //   //       //console.log("I was closed by the timer");
  //   //     }
  //   //   });
  // };
  scrollToView = (ref) => {
    ref.current.scrollIntoView();
  };
  handleClick = (ref) => {
    // //console.log(ref);
    ref.current.scrollIntoView();
  };
  handleDepartmentSelect = (e) => {
    if (e.target.value === "all") {
      this.setState({ doctorsList: this.state.doctors });
      return;
    }
    const doctorsList = this.state.doctors.filter((doctor) => {
      return doctor.doctorDepartment.toLowerCase() === e.target.value.toLowerCase();
    });
    this.setState({ doctorsList:[] });
    this.setState({ doctorsList });
  };

  render() {
    // useEffect(() => {

    // }, []);
    //
    const home = createRef();
    const services = createRef();
    const bookAppointment = createRef();

    return (
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <div className={"content is-fluid"}>
          <Navbar
            onClicked={this.handleClick}
            pages={[{ name: "Home", ref: home, link: "" }]}
          />
         
        </div>
        <div className="container is-centered">
          <label htmlFor="department">Select Department</label>
          <br />
          <div className="select is-medium">
            <select onChange={this.handleDepartmentSelect}>
              <option value={"all"}>All</option>
              {this.state.services.map((service) => {
                return <option>{service}</option>;
              })}
            </select>
          </div>
        </div>
        {/* <div
          ref={services}
          className={"container is-fluid"}
          style={{
            marginTop: "70px",
            paddingTop: "35px",
            paddingBottom: "35px",
            backgroundColor: "white",
          }}
        >
          <div className="content">
            <h1 style={{ fontSize: "30px", color: "black" }}>
              Our Specialities
            </h1>
            <p style={{ color: "grey" }}>
              Provision of the best services that exceed the expectations Of our
              valued customers.
            </p>
            <div>
              {this.state.services.length > 0 && (
                <Swiper
                  style={{ padding: "20px", height: "120px" }}
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                  breakpoints={{
                    640: {
                      width: 640,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 2,
                    },
                    1024: {
                      width: 1024,
                      slidesPerView: 3,
                    },
                    2048: {
                      width: 2048,
                      slidesPerView: 4,
                    },
                  }}
                  // scrollbar={{ draggable: true, }}
                  pagination={{ clickable: true }}
                  // navigation={{}}
                  // a11y={true}
                  autoplay={{
                    delay: 2500,
                    // disableOnInteraction: true,
                    pauseOnMouseEnter: true,
                  }}
                  loop={true}
                  // observer={true}
                  // pagination={{ clickable: true }}

                  onSlideChange={() => //console.log("slide change")}
                  onSwiper={(swiper) => //console.log(swiper)}
                >
                  {this.state.services.map((service) => {
                    return (
                      <SwiperSlide>
                        <div className="card">
                          <header className="card-header">
                            <p className="card-header-title is-centered">
                              {service}
                            </p>
                          </header>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
              {this.state.services.length === 0 && (
                <div className="content">
                  <h1 style={{ fontSize: "30px", color: "black" }}>
                    Loading ...
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div> */}
        <div
          ref={bookAppointment}
          className="container is-fluid"
          style={{
            paddingTop: "35px",
          }}
        >
          <div className="content">
            <h1 style={{ fontSize: "30px", color: "black", textAlign: "left" }}>
              Book Our Doctor
            </h1>
            <p style={{ textAlign: "left" }}>
              Finding the right doctor is what makes our service stand out!
              {/* Lorem ipsum  */}
            </p>
          </div>
          <div className="content">
            <Swiper
              style={{ padding: "20px", height: "520px" }}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              breakpoints={{
                640: {
                  width: 640,
                  slidesPerView: 1,
                },
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
                1024: {
                  width: 1024,
                  slidesPerView: 3,
                },
                2048: {
                  width: 2048,
                  slidesPerView: 4,
                },
              }}
              // scrollbar={{ draggable: true, }}
              pagination={{ clickable: true }}
              // navigation={{}}
              a11y={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              // loop={true}
              // observer={true}
              // pagination={{ clickable: true }}
            >
              {this.state.doctorsList.map((doctor) => {
                return (
                  <SwiperSlide>
                    <DoctorCard
                      name={doctor.doctorName}
                      id={doctor.id}
                      image={`https://appointment.whethiopia.com/images/${doctor.id}.png`}
                      speciality={doctor.specialityTitle}
                      onBook={this.handleBook}
                      onViewProfile={this.handleViewProfile}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div style={{ margin: "50px" }}></div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Index);
