import { wind } from "fontawesome";
import React from "react";
import { withRouter } from "../utils/withRouter";

const NavbarItem = (props) => (
  <a className="navbar-item is-capitalized" href={`#${props.page}`}>
    {props.page}
  </a>
);
const NavbarBurger = (props) => (
  <button
    onClick={props.toggleMenu}
    className={`button navbar-burger ${props.active ? "is-active" : ""}`}
  >
    <span />
    <span />
    <span />
  </button>
);
const Navbar = ({ pages, onClicked }) => {
  

  let navbarItems = pages.map((page) => (
    <a
      key={page.name}
      onClick={() => {
        if (page.ref==""){
          window.location.href = window.location.origin;
        }
        onClicked(page.ref);
      }}
      className="navbar-item is-capitalized"
      // href={`#${page.name}`}
    >
      {page.name}
    </a>
  ));
  return (
    <nav className={`content navbar `}>
      <div className="navbar-brand">
      <a
     
      
      className="navbar-item "
      href={`#`}
    >
      <img src="https://appointment.whethiopia.com/static/media/WMC-Banner-400x88-1_0.png" alt="Washington Logo" className="s" />
    </a>
        <NavbarBurger
          active={false}
          toggleMenu={() => {
          }}
        />
      </div>
      <div className={`navbar-menu ${false ? "is-active" : ""}`}>
        <div className="navbar-start"> {navbarItems} </div>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
